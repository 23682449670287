import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { routes } from 'constant';
import TechHome from 'fetures/TechHome';
import ScrollToTop from 'hooks/useScroll';
import { GuestRoute, PrivateRoute } from 'routes';
import HomeLayout from 'components/HomeLayout';
import AddSubscriptionDay from 'pages/AddSubscriptionDay';
import { ForgotPasswordPage, LoginPage, RegisterPage } from 'pages/Authentication';
import ResetPasswordPage from 'pages/Authentication/ResetPassword';
import CompanyProfilePage from 'pages/CompanyProfile';
import CompanyProfileEditPage from 'pages/CompanyProfile/edit';
import CurrentWorkSpacePage from 'pages/CurrentWorkSpace';
import DashBoardPage from 'pages/DashBoardPage';
import GenerateLink from 'pages/GenerateAndRefrelLink';
import HomeAbout from 'pages/HomeAbout';
import HomeContact from 'pages/HomeContact';
import HomePage from 'pages/HomePage';
import MarketingHome from 'pages/MarketingHome';
import MindMapPage from 'pages/MindMapPage';
import EditMindMapPage from 'pages/MindMapPage/edit';
import MyAccountPage from 'pages/MyAccountPage';
import PaymentSuccessUiPage from 'pages/PaymentSuccessUi';
import Privacy from 'pages/Privacy';
import Referral from 'pages/ReferralPage';
import StripeDemo from 'pages/StripeDemo';
import ActivateSubscription from 'pages/StripeDemo/activateSubscription';
import EmbedStripeDemo from 'pages/StripeDemo/EmbedStripeDemo';
import PaymentFail from 'pages/StripeDemo/PaymentFail';
import PaymentSuccess from 'pages/StripeDemo/PaymentSuccess';
import Terms from 'pages/Terms';
import TestPage from 'pages/Test/Index';
import WorkspaceEditPage from 'pages/WorkspacePage/edit';

import './App.css';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path={routes.test} element={<GuestRoute Component={TestPage} />} />
          <Route path={routes.register} element={<GuestRoute Component={RegisterPage} />} />
          <Route path={routes.login} element={<GuestRoute Component={LoginPage} />} />
          <Route path={routes.forgotPassword} element={<GuestRoute Component={ForgotPasswordPage} />} />
          <Route path={routes.resetPassword} element={<GuestRoute Component={ResetPasswordPage} />} />
          <Route path={routes.dashBoard} element={<PrivateRoute Component={DashBoardPage} />} />
          <Route path={routes.myAccount} element={<PrivateRoute Component={MyAccountPage} />} />
          <Route path={routes.activate_subscription} element={<PrivateRoute Component={ActivateSubscription} />} />
          <Route path={routes.paymentSuccess} element={<PrivateRoute Component={PaymentSuccessUiPage} />} />
          <Route path={routes.paymentSuccessUi} element={<PrivateRoute Component={PaymentSuccessUiPage} />} />
          <Route path={routes.paymentFail} element={<PrivateRoute Component={PaymentFail} />} />
          <Route path={routes.mindMap} element={<MindMapPage />} />
          <Route path={routes.home} element={<HomeLayout />}>
            <Route path={routes.home} element={<HomePage />} />
            <Route path={routes.marketingHome} element={<MarketingHome />} />
            <Route path={routes.tech} element={<TechHome />} />
            <Route path={routes.homeAbout} element={<HomeAbout />} />
            <Route path={routes.homeContact} element={<HomeContact />} />
            <Route path={routes.stripeDemo} element={<StripeDemo />} />
            <Route path={routes.stripeEmbedDemo} element={<EmbedStripeDemo />} />
            <Route path={routes.termsAndCondition} element={<Terms />} />
            <Route path={routes.privacyPolicy} element={<Privacy />} />
            <Route path={routes.generateLink} element={<PrivateRoute Component={GenerateLink} />} />
            <Route path={routes.referral} element={<GuestRoute Component={Referral} />} />
            <Route path={routes.addSubscriptionDay} element={<PrivateRoute Component={AddSubscriptionDay} />} />
          </Route>
          {/* <Route path={routes.currentWorkSpace} element={<GuestRoute Component={CurrentWorkSpace} />} /> */}
          <Route path={routes.editMindmap} element={<PrivateRoute Component={EditMindMapPage} />} />
          <Route path={routes.sharedLinkMindmap} element={<EditMindMapPage />} />
          <Route path={routes.currentWorkSpace} element={<GuestRoute Component={CurrentWorkSpacePage} />} />
          {/* Company Profile Routes */}
          <Route path={routes.companyProfile} element={<PrivateRoute Component={CompanyProfilePage} />} />
          <Route path={routes.companyProfileEdit} element={<PrivateRoute Component={CompanyProfileEditPage} />} />
          {/* New Workspace Routes */}
          <Route path={routes.workspaceEdit} element={<WorkspaceEditPage />} />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
      <Outlet />
    </div>
  );
};

export default App;
