/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useRef, useState } from 'react';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { TopNavigationBar } from 'components';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY, routes, TOAST_CONFIG } from 'constant';

import 'fetures/Workspace/style.css';
import {
  checkWorkspaceAccess,
  generateGroupNodesData as generateGroupNodesDataApi,
  updateWorkspaceApi as updateWorkspaceDataApi,
  getMindMapResults as generateMindmapNodesDataApi,
  getMindMapResultsCombined as generateCombinedNodesDataApi,
} from 'api/mindMamp';
import { catchBlock } from 'utils';

const WorkspaceEdit = () => {
  const navigate = useNavigate();
  const { id: workspaceId } = useParams();
  const { pathname } = useLocation();
  const goDiagram = useRef(null);
  const authToken = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);

  const [isLoading, setIsLoading] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [workspaceTitle, setWorkspaceTitle] = useState(null);
  const [workspaceData, setWorkspaceData] = useState(null);

  const { isLoading: isLoadingGetWorkspaceDetails } = useQuery({
    queryKey: ['get-workspace-details-with-access', workspaceId],
    queryFn: checkWorkspaceAccess,
    enabled: !!workspaceId,
    onSuccess: (response) => {
      const responseData = response.data;

      if (responseData.isAccess === false) {
        toast.error(response.message, TOAST_CONFIG);
        navigate(routes.dashBoard);
      } else if ((!authToken || authToken === '') && responseData?.work_space && !responseData.work_space.access_link) {
        handleRedirectToLogin();
      } else {
        setWorkspaceTitle(responseData?.work_space?.title || null);
        setWorkspaceData(responseData || null);
      }
    },
    onError: (error) => {
      catchBlock(error);

      navigate(
        (!authToken || authToken === '') &&
          error?.response?.data?.data?.work_space &&
          !error.response.data.data.work_space.access_link
          ? routes.login
          : routes.dashBoard,
      );
    },
  });

  const handleSetWorkspaceTitleEditing = () => {
    setIsTitleEditing(true);
  };

  const handleBlurWorkspaceTitle = (e) => {
    if (e.target.value) {
      setIsTitleEditing(false);
      setWorkspaceTitle(e.target.value);

      setTimeout(() => {
        handleUpdateWorkspaceData();
      }, [200]);
    }
  };

  const handleUpdateWorkspaceData = async () => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      if (diagram?.model?.nodeDataArray?.length > 0) {
        setIsLoading(true);

        await updateWorkspaceDataApi({
          id: workspaceData?.work_space?.id,
          title: workspaceTitle || '',
          output: JSON.stringify(diagram.model.nodeDataArray).replaceAll('\\\\', '') || '',
          area: workspaceData?.work_space?.area || '',
          token: workspaceData?.work_space?.token || '',
          context: workspaceData?.work_space?.context || '',
        }).finally(() => setIsLoading(false));
      }
    }

    return;
  };

  const handleRedirectToLogin = () => {
    localStorage.setItem(FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY, pathname);
    navigate(routes.login);
  };

  const handleInitDiagram = (workspace, isEditable, isAuthenticated) => {
    const $ = go.GraphObject.make;

    // Handler For Generate Workspace Initial Nodes
    const handleGenerateInitialNodes = (diagram) => {
      if (!diagram) return;

      const { nodeDataArray } = diagram.model;

      if (nodeDataArray.length > 0) {
        nodeDataArray
          .filter((node) => node.category === 'gridViewInitial')
          .map((nodeData) => {
            const node = diagram.findNodeForKey(nodeData.key);

            handleToggleLoader(true, node);

            generateGroupNodesDataApi({
              workspace_id: workspace.id,
              group: node.data.name,
            })
              .then((response) => {
                const generatedNodes = (response.data[0][node.data.name] || []).map((item) => ({
                  group: node.data.group,
                  category: 'gridView',
                  name: node.data.name,
                  data: { title: item.main, description: item.body },
                }));

                if (generatedNodes.length > 0) addNewNodes(node, generatedNodes);
              })
              .catch((error) => {
                handleToggleLoader(false, node);
                toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
              });
          });

        const mindmapNode = nodeDataArray.find(
          (node) => node.category === 'treeViewInitial' && node.name === 'mindmap',
        );

        if (mindmapNode) {
          const node = diagram.findNodeForKey(mindmapNode.key);
          handleGenerateNodes('treeViewIdea', node);
        }
      }

      return;
    };

    // Handler For Generate Nodes
    const handleGenerateNodes = async (category, node) => {
      if (!isAuthenticated && isEditable) await handleRedirectToLogin();
      if (!node) return;

      handleToggleLoader(true, node);

      const data = [];

      switch (category) {
        case 'gridView':
          data.push({
            group: node.data.group,
            category: 'gridView',
            name: node.data.name,
            data: {
              title: `Your new ${node.data.name} title here`,
              description: 'Type description here...',
            },
          });
          break;

        case 'treeViewYourIdea':
          data.push({
            parent: node.data.key,
            group: node.data.group,
            category: 'treeViewYourIdea',
            name: 'custom',
            data: { title: 'Your idea title here', description: 'Type description here...' },
          });
          break;

        case 'treeViewMerged':
          const idea1 = [node.data.data.title];
          const idea2 = [node.data.second.title];

          for (let n = node; n !== null; n = n.findTreeParentNode()) {
            idea1.push(n.data.data.title);
            idea2.push(n.data.data.title);
          }

          await generateCombinedNodesDataApi({
            idea1,
            idea2,
            workspaceId: workspace.id,
          })
            .then((response) => {
              (response[0] || []).forEach((item) => {
                data.push({
                  parent: node.data.key,
                  group: node.data.group,
                  category: 'treeViewIdea',
                  name: 'idea',
                  data: { title: item.main, description: item.body },
                });
              });
            })
            .catch((error) => {
              handleToggleLoader(false, node);

              toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
            });

          break;

        case 'treeViewIdea':
        case 'treeViewActionPlan':
        case 'treeViewMoreInfo':
          const context = [];

          for (let n = node; n !== null; n = n.findTreeParentNode()) {
            context.push({
              main: n.data.data.title,
              body: n.data.data.description,
            });
          }

          const prompt = context[0] || {};

          context.reverse();
          context.splice(-1);

          const ideas = [];

          diagram.nodes.each((n) => {
            if (
              n.data.key !== node.data.key &&
              n.data.parent === node.data.parent &&
              n.data.category === 'treeViewIdea'
            )
              ideas.push(n.data.data.title);
          });

          const generationType =
            category === 'treeViewIdea' ? 'IDEA' : category === 'treeViewActionPlan' ? 'EXAMPLE' : 'INFO';

          await generateMindmapNodesDataApi({
            prompt,
            context,
            ideas,
            generation_type: generationType,
            workspace_id: workspace.id,
          })
            .then((response) => {
              if (category === 'treeViewIdea') {
                (response[0] || []).forEach((item) => {
                  data.push({
                    parent: node.data.key,
                    group: node.data.group,
                    category: 'treeViewIdea',
                    name: 'idea',
                    data: { title: item.main, description: item.body },
                  });
                });
              } else if (category === 'treeViewActionPlan') {
                const actionSteps = (response[0] || []).map((item) => item.body);

                data.push({
                  parent: node.data.key,
                  group: node.data.group,
                  category: 'treeViewActionPlan',
                  name: 'example',
                  data: { title: 'Action Steps', list: actionSteps },
                });
              } else {
                const infoList = (response[0] || []).filter((item) => item !== '');

                data.push({
                  parent: node.data.key,
                  group: node.data.group,
                  category: 'treeViewMoreInfo',
                  name: 'info',
                  data: { title: 'More Information', list: infoList },
                });
              }
            })
            .catch((error) => {
              handleToggleLoader(false, node);

              toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
            });

          break;

        default:
          handleToggleLoader(false, node);
          break;
      }

      if (data.length > 0) await addNewNodes(node, data);
      return;
    };

    // Handler For Add New Nodes to Workspace
    const addNewNodes = async (node, data) => {
      handleToggleLoader(false, node);

      const { model } = diagram;
      model.startTransaction('Add New Nodes');

      data.forEach((nodeData) => {
        model.addNodeData(nodeData);

        if (nodeData.parent) {
          const newNode = diagram.findNodeForData(nodeData);

          model.addLinkData({ from: nodeData.parent, to: newNode.key });
        }
      });

      model.commitTransaction('Add New Nodes');

      // Rearrange Layout
      diagram.layoutDiagram(true);

      handleUpdateWorkspaceData();

      return;
    };

    // Handler For Toggle Loader
    const handleToggleLoader = (isShow, node) => {
      if (!node) return;
      var loader = node.findObject('nodeLoader');
      loader.visible = isShow;

      if (isShow) {
        var animation = new go.Animation();
        animation.add(loader, 'angle', 0, 360);
        animation.runCount = Infinity; // Loop indefinitely
        animation.start();
      } else if (node.currentAnimation) {
        node.currentAnimation.stop();
      }
    };

    // Handler For Text Edited
    const handleTextEdited = (object, oldString, newString) => {
      const node = object.part;
      const { diagram } = node;
      const { model } = diagram;

      const propertyName = object.name.replace('node', '').toLowerCase();

      const updatedData = {
        ...node.data,
        data: {
          ...node.data.data,
          [propertyName]: newString,
        },
      };

      model.startTransaction('updateNodeData');
      model.assignAllDataProperties(node.data, updatedData);
      model.updateTargetBindings(node.data);
      model.commitTransaction('updateNodeData');

      handleUpdateWorkspaceData();

      return;
    };

    // Handler For Show Delete Button
    const handleShowDeleteButton = (event, object) => {
      const nodeDeleteButton = object.part.findObject('nodeDeleteButton');
      if (nodeDeleteButton) nodeDeleteButton.visible = true;
    };

    // Handler For Hide Delete Button
    const handleHideDeleteButton = (event, node) => {
      const nodeDeleteButton = node.findObject('nodeDeleteButton');
      if (nodeDeleteButton && nodeDeleteButton.visible) nodeDeleteButton.visible = false;
    };

    // Handler For Delete Node
    const handleDeleteNode = (event, object) => {
      const node = object.part;

      diagram.remove(node);

      handleUpdateWorkspaceData();
    };

    // Diagram Config
    const diagram = $(go.Diagram, {
      allowCopy: false,
      allowDelete: true,
      maxSelectionCount: 1,
      initialDocumentSpot: go.Spot.Top,
      initialViewportSpot: go.Spot.Top,
      initialContentAlignment: go.Spot.Top,
      validCycle: go.Diagram.CycleDestinationTree,
      scrollMode: go.Diagram.InfiniteScroll,
      padding: new go.Margin(150, 0, 0, 0),
      'commandHandler.zoomFactor': 1.1,
      'undoManager.isEnabled': true,
      'draggingTool.dragsTree': true,
      'draggingTool.dragsLink': true,
      'grid.visible': true,
      model: $(go.GraphLinksModel, {
        linkKeyProperty: 'key',
      }),
      layout: $(go.GridLayout, {
        wrappingWidth: Infinity,
        wrappingColumn:
          (JSON.parse(workspace.output) || []).filter((node) => node.category == 'gridView' && node.isGroup).length ||
          0,
        alignment: go.GridLayout.Position,
        cellSize: new go.Size(1, 1),
        spacing: new go.Size(50, 50),
        comparer(a, b) {
          var acat = a.data.category;
          var bcat = b.data.category;
          if (acat === 'gridView' && bcat !== 'gridView') return -1;
          if (acat !== 'gridView' && bcat === 'gridView') return 1;
          return 0;
        },
      }),
      ViewportBoundsChanged: (e) => {
        const { diagram } = e;
        const { scale } = diagram;
        const baseSize = 25;

        const newSize = baseSize / scale;
        diagram.grid.gridCellSize = new go.Size(newSize, newSize);

        diagram.grid.findObject('lineH1').strokeWidth = 0.5 / scale;
        diagram.grid.findObject('lineH2').strokeWidth = 0.5 / scale;
        diagram.grid.findObject('lineV1').strokeWidth = 0.5 / scale;
        diagram.grid.findObject('lineV2').strokeWidth = 0.5 / scale;
      },
    });

    // Diagram Make Unique Key Function
    diagram.model.makeUniqueKeyFunction = (model, data) => {
      let key = 1;
      while (model.findNodeDataForKey(key) !== null) key++;
      return key;
    };

    // Background Grid Template
    diagram.grid = $(
      go.Panel,
      'Grid',
      { gridCellSize: new go.Size(25, 25) },
      $(go.Shape, 'LineH', { stroke: 'rgba(240, 84, 54, 0.02)', strokeWidth: 0.5, interval: 1, name: 'lineH1' }),
      $(go.Shape, 'LineH', { stroke: 'rgba(240, 84, 54, 0.20)', strokeWidth: 0.5, interval: 4, name: 'lineH2' }),
      $(go.Shape, 'LineV', { stroke: 'rgba(240, 84, 54, 0.02)', strokeWidth: 0.5, interval: 1, name: 'lineV1' }),
      $(go.Shape, 'LineV', { stroke: 'rgba(240, 84, 54, 0.20)', strokeWidth: 0.5, interval: 4, name: 'lineV2' }),
    );

    // Tree View Group Template
    diagram.groupTemplateMap.add(
      'treeView',
      $(go.Group, 'Auto', {
        layout: $(go.TreeLayout, {
          treeStyle: go.TreeLayout.StyleLastParents,
          arrangement: go.TreeLayout.ArrangementHorizontal,
          angle: 90,
          layerSpacing: 35,
          alternateAngle: 90,
          alternateLayerSpacing: 35,
          alternateNodeSpacing: 20,
          alternatePortSpot: new go.Spot(0.5, 1, 0, 0),
          alternateChildPortSpot: go.Spot.Top,
          nodeSpacing: 100,
        }),
        height: 'auto',
        width: 'auto',
      }),
    );

    // Grid View Group Template
    diagram.groupTemplateMap.add(
      'gridView',
      $(
        go.Group,
        'Auto',
        {
          layout: $(go.GridLayout, {
            wrappingColumn: 3,
            spacing: new go.Size(20, 20),
            alignment: go.GridLayout.Position,
          }),
          height: 'auto',
          width: 'auto',
        },
        $(
          go.Panel,
          'Auto',
          $(go.Shape, 'RoundedRectangle', {
            fill: 'transparent',
            stroke: '#F05436',
            strokeWidth: 2,
            shadowVisible: true,
          }),
          $(
            go.Panel,
            'Vertical',
            { margin: 5, padding: new go.Margin(5, 5, 5, 5) },
            $(
              go.TextBlock,
              {
                margin: new go.Margin(0, 0, 10, 0),
                font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                stroke: '#F05436',
                alignment: go.Spot.TopLeft,
                wrap: go.TextBlock.WrapFit,
                overflow: go.TextBlock.OverflowEllipsis,
              },
              new go.Binding('text', 'data', (data, obj) => {
                return data.title || '';
              }),
            ),
            $(go.Placeholder, { padding: 10 }),
          ),
        ),
      ),
    );

    // Tree View Initial Node Template
    diagram.nodeTemplateMap.add(
      'treeViewInitial',
      $(
        go.Node,
        'Spot',
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(40, 5, 0, 5),
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return nodeLoader.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },
            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return nodeLoader.visible ? 0.4 : 1;
            }).ofObject('nodeLoader'),
            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(45, 13, 0, 13),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    minSize: new go.Size(NaN, 220),
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                    },
                    new go.Binding('text', 'data', (data) => {
                      return data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),
                ),
              ),

              $(
                go.Panel,
                'Horizontal',
                {
                  alignment: go.Spot.Bottom,
                  defaultAlignment: go.Spot.Left,
                  stretch: go.GraphObject.Horizontal,
                  margin: new go.Margin(10, 0, 10, 0),
                  visible: isEditable,
                },
                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 10, 0, 0),
                    cursor: 'pointer',
                    click: (e, object) => handleGenerateNodes('treeViewIdea', object.part),
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    fill: '#F05436',
                    strokeWidth: 0,
                  }),
                  $(go.Picture, {
                    source: '/assets/images/idea-white.svg',
                    desiredSize: new go.Size(20, 20),
                    margin: new go.Margin(5, 5, 5, 5),
                  }),
                ),
                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 0, 0, 0),
                    cursor: 'pointer',
                    click: (e, object) => handleGenerateNodes('treeViewActionPlan', object.part),
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    fill: '#F05436',
                    strokeWidth: 0,
                  }),
                  $(go.Picture, {
                    source: '/assets/images/list-white.svg',
                    desiredSize: new go.Size(20, 20),
                    margin: new go.Margin(5, 5, 5, 5),
                  }),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),
        ),

        $(
          go.Panel,
          'Auto',
          {
            alignment: new go.Spot(1, 1, -20, -18),
            alignmentFocus: go.Spot.BottomRight,
            margin: new go.Margin(-0, 0, 0, 0),
            cursor: 'pointer',
            visible: isEditable,
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return nodeLoader.visible ? 0.4 : 1;
          }).ofObject('nodeLoader'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091D',
            strokeWidth: 1,
            stroke: '#F05436',
          }),

          $(go.Picture, {
            source: '/assets/images/add-new.png',
            desiredSize: new go.Size(20, 20),
            margin: new go.Margin(5, 5, 5, 5),
          }),
        ),

        $(
          go.Panel,
          'Spot',
          {
            alignment: go.Spot.Top,
          },
          $(go.Shape, 'Circle', {
            width: 50,
            shadowVisible: true,
            strokeWidth: 0,
            fill: '#09091D',
          }),
          $(go.Picture, {
            name: 'nodeIcon',
            desiredSize: new go.Size(26, 26),
            margin: 1,
            source: '/assets/images/goal.svg',
          }),
        ),
      ),
    );

    // Tree View Idea
    diagram.nodeTemplateMap.add(
      'treeViewIdea',
      $(
        go.Node,
        'Spot',
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(40, 5, 0, 5),
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return nodeLoader.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },
            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return nodeLoader.visible ? 0.4 : 1;
            }).ofObject('nodeLoader'),
            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(45, 13, 0, 13),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    minSize: new go.Size(NaN, 160),
                    margin: new go.Margin(0, 0, 60, 0),
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                    },
                    new go.Binding('text', 'data', (data) => {
                      return data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      spacingBelow: 1.5,
                      visible: true,
                      name: 'nodeDescription',
                      row: 2,
                      column: 0,
                      columnSpan: 2,
                      stroke: 'white',
                      font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, 92),
                      margin: new go.Margin(15, 0, 0, 0),
                      stretch: go.GraphObject.Fill,
                      overflow: go.TextBlock.OverflowEllipsis,
                      textEdited: handleTextEdited,
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.description;
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeDescriptionCollapseButton',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: '9pt poppins, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      stroke: '#F05436',
                      margin: new go.Margin(10, 0, 0, 0),
                      isUnderline: true,
                      cursor: 'pointer',
                      text: 'Show more',
                      click: (e, object) => {
                        const node = object.part;
                        if (!node) return;

                        const button = node.findObject('nodeDescriptionCollapseButton');
                        const description = node.findObject('nodeDescription');

                        if (button.text === 'Show more') {
                          description.maxSize = new go.Size(325, NaN);
                          button.text = 'Show less';
                        } else {
                          description.maxSize = new go.Size(325, 92);
                          button.text = 'Show more';
                        }
                      },
                    },
                    new go.Binding('visible', 'data', (data) => {
                      if (data.description) {
                        const offscreenDiv = document.createElement('div');
                        offscreenDiv.style.position = 'absolute';
                        offscreenDiv.style.visibility = 'hidden';
                        offscreenDiv.style.width = '325px';
                        offscreenDiv.style.font = '9pt poppinsregular, Helvetica, Arial, sans-serif';
                        offscreenDiv.style.lineHeight = '1.25';
                        offscreenDiv.style.whiteSpace = 'normal';
                        offscreenDiv.style.overflow = 'hidden';
                        offscreenDiv.innerText = data.description;

                        document.body.appendChild(offscreenDiv);
                        const height = offscreenDiv.clientHeight;
                        document.body.removeChild(offscreenDiv);

                        return height > 92;
                      }
                      return false;
                    }),
                  ),
                ),
              ),

              $(
                go.Panel,
                'Horizontal',
                {
                  alignment: go.Spot.Bottom,
                  defaultAlignment: go.Spot.Left,
                  stretch: go.GraphObject.Horizontal,
                  margin: new go.Margin(10, 0, 10, 0),
                  visible: isEditable,
                },
                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 10, 0, 0),
                    cursor: 'pointer',
                    click: (e, object) => handleGenerateNodes('treeViewIdea', object.part),
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    fill: '#F05436',
                    strokeWidth: 0,
                  }),
                  $(go.Picture, {
                    source: '/assets/images/idea-white.svg',
                    desiredSize: new go.Size(20, 20),
                    margin: new go.Margin(5, 5, 5, 5),
                  }),
                ),
                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 0, 0, 0),
                    cursor: 'pointer',
                    click: (e, object) => handleGenerateNodes('treeViewActionPlan', object.part),
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    fill: '#F05436',
                    strokeWidth: 0,
                  }),
                  $(go.Picture, {
                    source: '/assets/images/list-white.svg',
                    desiredSize: new go.Size(20, 20),
                    margin: new go.Margin(5, 5, 5, 5),
                  }),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),
        ),

        $(
          go.Panel,
          'Auto',
          {
            alignment: new go.Spot(1, 1, -20, -18),
            alignmentFocus: go.Spot.BottomRight,
            margin: new go.Margin(-0, 0, 0, 0),
            cursor: 'pointer',
            visible: isEditable,
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return nodeLoader.visible ? 0.4 : 1;
          }).ofObject('nodeLoader'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091D',
            strokeWidth: 1,
            stroke: '#F05436',
          }),

          $(go.Picture, {
            source: '/assets/images/add-new.png',
            desiredSize: new go.Size(20, 20),
            margin: new go.Margin(5, 5, 5, 5),
          }),
        ),

        $(
          go.Panel,
          'Spot',
          {
            alignment: go.Spot.Top,
          },
          $(go.Shape, 'Circle', {
            width: 50,
            shadowVisible: true,
            strokeWidth: 0,
            fill: '#09091D',
          }),
          $(go.Picture, {
            name: 'nodeIcon',
            desiredSize: new go.Size(26, 26),
            margin: 1,
            source: '/assets/images/idea.svg',
          }),
        ),
      ),
    );

    // Tree View Action Plan
    diagram.nodeTemplateMap.add(
      'treeViewActionPlan',
      $(go.Node, 'Spot', {
        selectionObjectName: 'nodeWrapper',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
      }),
    );

    // Tree View More Info
    diagram.nodeTemplateMap.add(
      'treeViewMoreInfo',
      $(go.Node, 'Spot', {
        selectionObjectName: 'nodeWrapper',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
      }),
    );

    // Tree View Your Idea
    diagram.nodeTemplateMap.add(
      'treeViewYourIdea',
      $(go.Node, 'Spot', {
        selectionObjectName: 'nodeWrapper',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
      }),
    );

    // Tree View Merged Node
    diagram.nodeTemplateMap.add(
      'treeViewMerged',
      $(go.Node, 'Spot', {
        selectionObjectName: 'nodeWrapper',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
      }),
    );

    // Grid View Initial Node Template
    diagram.nodeTemplateMap.add(
      'gridViewInitial',
      $(
        go.Node,
        'Spot',
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
          visible: isEditable,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(10, 10, 10, 10),
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return nodeLoader.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },
            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return nodeLoader.visible ? 0.4 : 1;
            }).ofObject('nodeLoader'),
            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(13, 13, 13, 13),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    minSize: new go.Size(NaN, 162),
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                      margin: new go.Margin(25, 0, 0, 0),
                    },
                    new go.Binding('text', 'data', (data) => {
                      return data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),

                  $(
                    go.Panel,
                    'Spot',
                    {
                      margin: new go.Margin(13, 13, 13, 13),
                      width: 80,
                      height: 80,
                      cursor: 'pointer',
                      click: (e, object) => handleGenerateNodes('gridView', object.part),
                    },
                    $(go.Shape, 'Circle', {
                      fill: '#F05436',
                      desiredSize: new go.Size(80, 80),
                    }),
                    $(go.Picture, {
                      name: 'nodeIcon',
                      desiredSize: new go.Size(20, 20),
                      source: '/assets/images/add.png',
                      alignment: go.Spot.Center,
                      margin: 1,
                    }),
                  ),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),
        ),
      ),
    );

    // Grid View Node Template
    diagram.nodeTemplateMap.add(
      'gridView',
      $(
        go.Node,
        'Spot',
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
          click: handleHideDeleteButton,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(10, 10, 10, 10),
            contextClick: handleShowDeleteButton,
            cursor: 'pointer',
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
            return nodeLoader.visible || nodeDeleteButton.visible ? 0.9 : 1;
          })
            .ofObject('nodeLoader')
            .ofObject('nodeDeleteButton'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },
            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : 1;
            })
              .ofObject('nodeLoader')
              .ofObject('nodeDeleteButton'),
            new go.Binding('pickable', '', (data, object) => {
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
              return !nodeDeleteButton.visible;
            }).ofObject('nodeDeleteButton'),

            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(13, 13, 13, 13),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    minSize: new go.Size(NaN, 162),
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: isEditable && isAuthenticated,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                      textEdited: handleTextEdited,
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      spacingBelow: 1.5,
                      visible: true,
                      name: 'nodeDescription',
                      row: 2,
                      column: 0,
                      columnSpan: 2,
                      stroke: 'white',
                      font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                      editable: isEditable && isAuthenticated,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, 92),
                      margin: new go.Margin(15, 0, 0, 0),
                      stretch: go.GraphObject.Fill,
                      overflow: go.TextBlock.OverflowEllipsis,
                      textEdited: handleTextEdited,
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.description;
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeDescriptionCollapseButton',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: '9pt poppins, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      stroke: '#F05436',
                      margin: new go.Margin(10, 0, 0, 0),
                      isUnderline: true,
                      cursor: 'pointer',
                      text: 'Show more',
                      click: (e, object) => {
                        const node = object.part;
                        if (!node) return;

                        const button = node.findObject('nodeDescriptionCollapseButton');
                        const description = node.findObject('nodeDescription');

                        if (button.text === 'Show more') {
                          description.maxSize = new go.Size(325, NaN);
                          button.text = 'Show less';
                        } else {
                          description.maxSize = new go.Size(325, 92);
                          button.text = 'Show more';
                        }
                      },
                    },
                    new go.Binding('visible', 'data', (data) => {
                      if (data.description) {
                        const offscreenDiv = document.createElement('div');
                        offscreenDiv.style.position = 'absolute';
                        offscreenDiv.style.visibility = 'hidden';
                        offscreenDiv.style.width = '325px';
                        offscreenDiv.style.font = '9pt poppinsregular, Helvetica, Arial, sans-serif';
                        offscreenDiv.style.lineHeight = '1.25';
                        offscreenDiv.style.whiteSpace = 'normal';
                        offscreenDiv.style.overflow = 'hidden';
                        offscreenDiv.innerText = data.description;

                        document.body.appendChild(offscreenDiv);
                        const height = offscreenDiv.clientHeight;
                        document.body.removeChild(offscreenDiv);

                        return height > 92;
                      }
                      return false;
                    }),
                  ),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),

          $(
            go.Panel,
            'Auto',
            {
              visible: false,
              name: 'nodeDeleteButton',
              alignment: go.Spot.Center,
              cursor: 'pointer',
              click: handleDeleteNode,
            },

            $(go.Shape, 'RoundedRectangle', {
              fill: '#09091D',
              stroke: '#F05436',
              strokeWidth: 1,
              height: 35,
              width: 100,
            }),

            $(
              go.Panel,
              'Horizontal',
              {
                alignment: go.Spot.Center,
              },
              $(go.Picture, {
                source: '/assets/images/bin.svg',
                desiredSize: new go.Size(20, 20),
                margin: new go.Margin(0, 5, 0, 0),
              }),
              $(go.TextBlock, 'Delete', {
                margin: new go.Margin(5, 0, 0, 5),
                stroke: '#F05436',
                font: 'bold 12pt sans-serif',
              }),
            ),
          ),
        ),
      ),
    );

    // Link Template
    diagram.linkTemplate = $(
      go.Link,
      go.Link.Orthogonal,
      { layerName: 'Background', corner: 5 },
      $(
        go.Shape,
        new go.Binding('stroke', 'isHighlighted', (h) => {
          return h ? '#F05436' : '#F05436';
        }).ofObject(),
        {
          strokeWidth: 7,
          stroke: '#F05436',
        },
      ),
      {
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Bottom,
        fromPortId: 'nodeToggleButtonPort',
      },
    );

    // Diagram Initial Layout Completed Listener
    diagram.addDiagramListener('InitialLayoutCompleted', (e) => {
      const { diagram } = e;

      // Generate initial nodes
      if (isAuthenticated && isEditable && (!workspace.status || workspace.status === 0))
        handleGenerateInitialNodes(diagram);
    });

    return diagram;
  };

  return (
    <div className="workspace_edit-container">
      {isLoadingGetWorkspaceDetails && (
        <div className="workspace_edit-spinner-wrapper">
          <Spinner animation="border" className="workspace_edit-spinner" />
        </div>
      )}

      <TopNavigationBar
        wrapperClass="workspace_edit-top-navigation-bar"
        isShowCompanyDropdown={false}
        logoLink={routes.dashBoard}
        isShowTrialAlert={!!(authToken && authToken !== '')}
        isShowToggleDropdown={!!(authToken && authToken !== '')}
        isShowProcessingAlert={!!(authToken && authToken !== '')}
        processingAlertText={isLoading ? 'Saving...' : 'Saved'}
      />

      <div className="workspace_edit-workspace-title">
        {isTitleEditing && authToken && authToken !== '' && workspaceData?.role === 'editor' ? (
          <input
            type="text"
            placeholder="Enter Workspace Title"
            defaultValue={workspaceTitle}
            onBlur={handleBlurWorkspaceTitle}
            autoFocus
          />
        ) : (
          <h1 onClick={handleSetWorkspaceTitleEditing}>{workspaceTitle}</h1>
        )}
      </div>

      {workspaceData && workspaceData?.work_space?.output && (
        <div className="workspace_edit-diagram-wrapper">
          <ReactDiagram
            ref={goDiagram}
            initDiagram={() =>
              handleInitDiagram(
                workspaceData.work_space,
                workspaceData.role === 'editor',
                authToken && authToken !== null,
              )
            }
            divClassName="workspace_edit-diagram"
            nodeDataArray={JSON.parse(workspaceData.work_space.output) || []}
            linkDataArray={(JSON.parse(workspaceData.work_space.output) || []).reduce((links, node) => {
              if (node.parent) {
                links.push({ from: node.parent, to: node.key });
              }
              return links;
            }, [])}
            skipsDiagramUpdate
          />
        </div>
      )}
    </div>
  );
};

export default WorkspaceEdit;
